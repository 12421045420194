<template>
  <div class="pt-container">
    <div class="pt-header">
      <div class="pt-title">
        Produtos cadastrados
        <span id="help-icon" class="icon-box"><HelpCircle class="icon stroke" /></span>

        <b-tooltip target="help-icon" placement="bottom">
          Cadastre produtos para efetuar entradas, saídas e possuir gestão completa do seu estoque de forma simples
        </b-tooltip>
      </div>

      <div>
        <b-button class="wh-button no-print" variant="outline-primary" outline @click="getProductsXlsx">
            Download xlsx
        </b-button>
      </div>
    </div>

    <div class="row">
      <div class="col-5">
        <b-form-group class="form-group">
          <label for="search">Produto</label>
          <b-form-input
            class="placeholder-color"
            id="search"
            debounce="500"
            autocomplete="off"
            placeholder="Buscar produto"
            v-model="query"
          />
        </b-form-group>
      </div>

      <div class="col-2">
        <Periods
          label="Período"
          @select="handlePeriod"
        />
      </div>

      <div class="col-2">
        <Select
          label="Quantidade mínima"
          placeholder="Selecionar"
          v-model="quantity"
          v-bind:options="[{ label: 'Selecionar', value: null }, { label: 'Abaixo do definido', value: -1 },{ label: 'Acima do definido', value: 1 }]"
          v-on:onChange="(value) => this.quantity = value"
        />
      </div>
    </div>

    <ProductsTable
      :products="products"
      :onEdit="onProductEditClick"
      :onDelete="deleteProduct"
      :loading="loading"
      :hasFilter="hasFilter"
    />

    <div class="pagination-position">
      <b-pagination
        size="sm"
        v-model="page"
        :total-rows="count"
        :per-page="limit"
        first-number
        last-number
      />
    </div>

  </div>
</template>

<script>
import { EventBus } from '@/utils/eventBus';
import { saveAs } from 'file-saver';

export default {
  name: 'products-table',
  components: {
    HelpCircle: () => import('@/assets/icons/help-circle.svg'),
    Periods: () => import('@/components/General/Periods'),
    ProductsTable: () => import('@/components/Warehouse/ProductsTable'),
    Select: () => import('@/components/Select'),
  },
  props: {
    onProductEditClick: Function,
  },
  mounted () {
    if (this.$route.query.p === 'ls') {
      this.quantity = -1;
    }
    this.getProducts();
    this.getProviders();
    EventBus.$on('reloadProducts', () => this.getProducts(true));
  },
  data() {
    const clinic = JSON.parse(localStorage.getItem('clinic'));
    return {
      clinicId: clinic.id,
      page: 1,
      limit: 0,
      count: 0,
      query: '',
      provider: null,
      providerQuery: null,
      start: null,
      end: null,
      quantity: null,
      products: [],
      providers: [],
      product: null,
      loading: false,
      hasFilter: false,
    }
  },
  methods: {
    handlePeriod(period) {
      this.start = period.start;
      this.end = period.end;
    },
    getProducts(hideLoading) {
      this.loading = true;
      this.hasFilter = (this.query || this.provider || this.start || this.quantity);
      let isLoading = null
      if(!hideLoading) {
        isLoading = this.$loading.show();
      }
      this.api.getProducts(this.clinicId, this.page, this.query, this.provider, this.start, this.end, this.quantity)
      .then(res => {
        this.products = res.data.data;
        this.count = res.data.total;
        this.limit = res.data.per_page;
      })
      .catch(err => this.$toast.error(err.message))
      .finally(() => {
        this.loading = false;
        isLoading && isLoading.hide();
      });
    },
    s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i=0; i!==s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    },
    getProductsXlsx() {
      const isLoading = this.$loading.show();
      const data = {
        'query': this.query,
        'provider': this.provider,
        'start': this.start,
        'end': this.end,
        'quantity': this.quantity
      }
      this.api.getProductsExport(this.clinicId, data)
      .then(res => {
        saveAs(
          new Blob([res.data], {
            type: 'application/vnd.ms-excel',
          }),
          'produtos.xlsx'
        );
      })
      .catch(err => this.$toast.error(err.message))
      .finally(() => isLoading.hide());
    },
    deleteProduct(product) {
      const isLoading = this.$loading.show();
      this.api.deleteProduct(product.id)
      .then(() => {
        this.$toast.success('Produto excluído com sucesso!');
        this.getProducts();
      })
      .catch(err => this.$toast.error(err.message))
      .finally(() => isLoading.hide());
    },
    getProviders() {
      this.api.getProviders(this.clinicId, 1, this.providerQuery)
      .then(res => {
        this.providers = res.data.data;
      })
      .catch(err => this.$toast.error(err.message));
    },
  },
  watch: {
    query: function() { this.getProducts(); },
    provider: function() { this.getProducts(); },
    start: function() { this.getProducts(); },
    quantity: function() { this.getProducts(); },
    page: function() { this.getProducts(); },
    providerQuery: function() { this.getProviders(); },
  }
}
</script>
<style scoped lang="scss">
  .pt-container {
    padding: 30px 10px;
    .pt-header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0 30px 0;
    }
    .pt-title {
      display: inline-flex;
      align-items: center;
      font-family: 'Red Hat Display';
      font-weight: 500;
      font-size: 18px;
    }

    .placeholder-color::placeholder {
      color: var(--type-placeholder);
    }

    .wh-button {
      margin: 0 0 0 10px;
      border-color: var(--blue-500);
      color: var(--blue-500);
    }
    .icon {
      height: 24px;
      width: 24px;
      margin-left: 5px;
      stroke: var(--neutral-500);
    }
    .pagination-position {
      width: 100%;
      display: inline-flex;
      justify-content: flex-end;
    }
    label {
      width: 100%;
      font-family: 'Nunito Sans';
      font-weight: 700;
      font-size: 16px;
      color: #525C7A;
      text-align: left !important;
      color: var(--dark-blue);
      margin-bottom: 4px;
    }
  }
</style>
